import React from "react";

const Footer = () => {
  return (
    <footer id="contact" className="bg-black text-white">
      <div className="container mx-auto">
        <div className="flex flex-col md:py-30 pt-12 pb-20 md:gap-24 gap-16">
          <div>
            <h3 className="md:text-display-xl text-display-md font-semibold pb-4">
              Tell us about your project!
            </h3>
            <a
              href="mailto:info@diff-ict.be"
              className="md:text-display-xl text-display-md font-normal hover:text-primary-100 hover:underline">
              Let’s talk ↗
            </a>
          </div>
          <div className="flex md:flex-row flex-col md:gap-8 gap-12 justify-between">
            <div className="grid md:grid-cols-3 grid-cols-2 md:gap-12 gap-3 md:pl-32">
            </div>
          </div>
        </div>
        <div>
          <p className="text-sm text-neutral-500">Copyright &copy; 2023. All rights reserved. Diff-ICT Comm.V. - BE0742.600.227</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
